import React from "react";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { UserProfileDetails } from "../../services/srvc-users-realm";
import FormNeeded from "../webx/form-needed";

export default function UserScanDefaultModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [dataProfile, setDataProfile] = useState([]);

  useEffect(() => {
    
      const fetchData = async () => {
        setLoader(true);

        const result = await UserProfileDetails({
          data: {
            user: id,
          },
        });

        if (result.stat) {
          // && resAssets.stat
          setDataProfile(result.data);
          setLoader(false);

        }

      };
   
    fetchData();
  }, []);

  const generateVCard = () => {
    const vCardString = `
BEGIN:VCARD
VERSION:3.0
N:${dataProfile?.name || ""}
ORG:${dataProfile?.organization || ""}
TEL;TYPE=WORK:${dataProfile?.contact?.mobile || ""}
TEL;TYPE=whatsapp:${dataProfile?.contact?.whatsapp || ""}
EMAIL:${dataProfile?.mail || ""}
ADR;TYPE=Address:${dataProfile?.address?.city || ""};${
      dataProfile?.address?.state || ""
    };${dataProfile?.address?.country || ""}
URL;TYPE=instagram:${dataProfile?.sites?.instagram || ""}
URL;TYPE=facebook:${dataProfile?.sites?.facebook || ""}
URL;TYPE=linkedin:${dataProfile?.sites?.linkedin || ""}

END:VCARD`;

    const blob = new Blob([vCardString], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Contact.vcf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );
console.log(dataProfile)
  return (<>
    {dataProfile?.length != 0 && (
    <div>
      <div className="back-color-wite rounded-xd p-3 border">
        <p
          className=" fw-bold text-color-tone m-0"
          style={{ fontSize: "20px" }}
        >
          Contact
        </p>
        <div className="me-auto">
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bx-user-circle bx-sm"></i>
            </p>
            <p
              className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
            >
              {dataProfile?.name || "******"}
            </p>
          </div>
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bx-envelope bx-sm"></i>
            </p>
            <p
              className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
            >
              {dataProfile?.contact?.mail}
            </p>
          </div>
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bxl-whatsapp bx-sm"></i>
            </p>
            <p className="text-color-next text-medium text-normal m-0">
              {dataProfile?.contact?.whatsapp}
            </p>
          </div>
          <div className="me-auto d-flex gap-4 my-2">
            <p className=" text-color-tone m-0">
              <i class="bx bx-phone-call bx-sm"></i>
            </p>
            <p className="text-color-next text-medium text-normal m-0">
              {dataProfile?.contact?.mobile}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="back-color-wite rounded-xd p-3 border mt-4">
          <div className="d-flex flex-column ">
            <div></div>
            <div className="me-auto w-100">
              <p
                className=" fw-bold text-color-tone m-0"
                style={{ fontSize: "20px" }}
              >
                Address
              </p>

              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-home-circle bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                >
                  {dataProfile?.address?.city},{dataProfile?.address?.district},
                  {dataProfile?.address?.state} ,{dataProfile?.address?.country}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="back-color-wite rounded-xd p-3 border mt-4">
          <div className="d-flex flex-column ">
            <div></div>

            <div className="me-auto ">
              <p
                className=" fw-bold text-color-tone m-0"
                style={{ fontSize: "20px" }}
              >
                Social Media
              </p>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bxl-instagram bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium  text-normal m-0 text-break
            "
                >
                  {dataProfile?.sites?.instagram}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bxl-facebook bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium  text-normal m-0 text-break
            "
                >
                  {dataProfile?.sites?.facebook}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bxl-linkedin bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium text-normal m-0 text-break
            "
                >
                  {dataProfile?.sites?.linkedin}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 " style={{ width: "15%" }}>
        <button
          className="btn btn-primary btn-sm text-small rounded-xx w-100"
          onClick={generateVCard}
        >
          <i class="bx bxs-download bx-sm"></i>
        </button>
      </div>
    </div>
    )}
      <div className={`${dataProfile.length == 0 ? "" : "d-none"}`}>
      <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-tint"></i>
          </span>
          <span className="ms-1 text-color-tone">No Data Found...</span>
        </div>
      </div>
      </>
  );
}

