// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'assets.discover')).link

// -----------------

export const AssetOfferList = async (item) => {
  
  const basx = base + '/assets/offers/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT    )

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetOfferCreate = async (item) => {
  
  const basx = base + '/assets/offers/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
export const AssetOfferStatusSet = async (item) => {
  
  const basx = base + '/assets/offers/status/set';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}