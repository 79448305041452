// main
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import DiscoverListEventsModule from "../content/discover/discover-list-events";
import DiscoverListMembersModule from "../content/discover/discover-list-members";
import DiscoverListTravelModule from "../content/discover/discover-list-travel";
import DiscoverListAcademicsModule from "../content/discover/discover-list-academics";

export default function Discover () {
  
  const metadata = {
    name: 'Discover',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: "small",
          show: true,
          data: (
            <>
              {" "}
              <WebbHeader
                data={{ name: metadata.name, home: "/", link: "" }}
              />{" "}
            </>
          ),
        }}
        media={{ size: "fluid", show: false, data: <></> }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              <div className="mb-1"></div>
              <DiscoverListEventsModule />

              <div className="mt-3 mb-1"></div>
              <DiscoverListTravelModule />

              <WebbDividerMedium />
              <DiscoverListMembersModule />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "small",
          show: true,
          data: (
            <>
              <div className="text-center back-color-lite">
                <WebbFooterMobile />
              </div>
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}