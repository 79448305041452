// auth
const CryptoJS = require('crypto-js');

export const AuthClearStore = async() => {
  // logout current user

  localStorage.clear();
  localStorage.setItem("auth",null);
  localStorage.setItem("indx",null);
  localStorage.setItem("bznx",null);
  localStorage.setItem("indz",null);
  localStorage.setItem("bznz",null);

}

export  const encrypt =  (data, secret) => {
  try {
   let string = JSON.stringify(data) 
   let encrypted = CryptoJS.AES.encrypt(string, secret).toString();
   return encrypted
  } catch (error) {
    // console.error('Encryption error:', error);
  }
};

export const decrypt =  (encryptedString, secret) => {
  try {
      let bytes = CryptoJS.AES.decrypt(encryptedString, secret)
      let data = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(data);
  } catch (error) {
    // console.error('Decryption error:', error);
  }
};

export const SetAuthUser= (asset)=>{
  const data =  encrypt(asset, process.env.REACT_APP_WEBB_SITE_SCRT)
  localStorage.setItem('auth', data);
}

export const GetAuthUser=  ()=>{
 const data =localStorage.getItem("auth");
 const authdata = decrypt(data, process.env.REACT_APP_WEBB_SITE_SCRT)
 return authdata  
}

export const SetLocalUser=  (asset)=>{
  const data =  encrypt(asset, process.env.REACT_APP_WEBB_SITE_SCRT)
  localStorage.setItem('indx', data);
}

export const GetLocalUser=  ()=>{
  const data= localStorage.getItem("indx")
  const indxdata =  decrypt(data, process.env.REACT_APP_WEBB_SITE_SCRT)
  return indxdata   
}

export const SetLocalBusiness=  (asset)=>{
  const data = encrypt(asset, process.env.REACT_APP_WEBB_SITE_SCRT)
  localStorage.setItem('bznx', data);
}

export const GetLocalBusiness= ()=>{
  const data =localStorage.getItem("bznx");
  const bznxdata = decrypt(data, process.env.REACT_APP_WEBB_SITE_SCRT)
  return bznxdata   
}

export const SetNewUser=(asset)=>{
  localStorage.setItem('indz', JSON.stringify(asset));
}

export const GetNewUser=()=>{
  return JSON.parse(localStorage.getItem("indz"));
}

export const SetNewBusiness=(asset)=>{
  localStorage.setItem('bznz', JSON.stringify(asset));
}

export const GetNewBusiness=()=>{
  return JSON.parse(localStorage.getItem("bznz"));
}