// init
import axios from 'axios'
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";


const base = services.data.find(x => x.code == 'transfers.funds').link

// -----------------

export const TransfersFundDetails = async item => {
  const basx = base + '/transfers/funds/details'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundList = async item => {
  const basx = base + '/transfers/funds/list'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundListAsset = async item => {
  const basx = base + '/transfers/funds/list/asset'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundListUser = async item => {
  const basx = base + '/transfers/funds/list/user'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundListUserWait = async item => {
  const basx = base + '/transfers/funds/list/users/wait'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundCreate = async item => {
  const basx = base + '/transfers/funds/create'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersFundSubmit = async item => {
  const basx = base + '/transfers/funds/submit'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundCancel = async item => {
  const basx = base + '/transfers/funds/cancel'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundDecline = async item => {
  const basx = base + '/transfers/funds/decline'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersFundAccountCredit = async item => {

  const basx = base + '/transfers/funds/account/credit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  // console.log(datx)
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

