// scan
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import QrReader from "react-qr-scanner";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { UserDefaultDataRead } from "../../services/srvc-users-realm";

export default function CodeScanModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  
  const [scanstate, setScanState] = useState(false)
  const [scan, setScan] = useState('******')

  const [user, setUser] = useState(false)
  const [data, setData] = useState({})


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        // console.log(scan);
        if (scan !== "******" && scan?.split(".")[1] == "asset" || scan?.split(".")[1] == "user" ) {
          // get user default profile
          setUser(true);
          setScanState(true);
          if(scan?.split(".")[1] == "asset"){
            navigate(`/user/discover/${scan.split(".")[2]}`);
          }
          if(scan?.split(".")[1] == "user"){
            navigate(`/ux/profile/${scan.split(".")[2]}`);
          }
          
          const result = await UserDefaultDataRead({
            data: { item: scan.split(".")[2] },
            srvc: "******",
          });
          // console.log(result);
          if (result.stat) setData(result.data);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [scan]);


    const handleScan = data => {
      setScanState(!scan);
      setScan(data?.text);
      // console.log(data)
    }

  const handleError = err => {
    // console.error(err);
  };

  return (
    <>
      {/* data */}
      <div className={scanstate && user ? "d-none" : ""}>
        <div  >
         <QrReader
         delay={300}
         onError={handleError}
         onScan={handleScan}
         style={{ width: '100%', height: '100%', backgroundColor: 'black'}}
         className="rounded-xd border"  />
        </div>
      </div>

    <div className={scanstate && loader ? '' : 'd-none'}>
      <p className="text-center">Please Wait...</p>
    </div>

    <div className={scanstate && !loader ? '' : 'd-none'}>
      <div className="d-flex">
        <div className="me-auto">
          <p className="m-0">{'Name'}</p>
          <p className="m-0">{'Email'}</p>
        </div>
        <div className="text-end">
          <p className="m-0">{data?.name || ''}</p>
          <p className="m-0">{data?.mail || ''}</p>
        </div>
      </div>
    </div>
  </>

  )
}

