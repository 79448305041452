// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'accounts')).link

// -----------------

export const AccountsMinterStatus = async (item) => {
  
  const basx = base + '/accounts/minter/status';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)
  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AccountsMinterCreate = async (item) => {
  
  const basx = base + '/accounts/minter/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AccountsMinterDetails = async (item) => {
  
  const basx = base + '/accounts/minter/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    // console.log(data)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AccountsMinterInit = async (item) => {
  
  const basx = base + '/accounts/minter/init';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AccountsMinterAssetStatus = async (item) => {
  
  const basx = base + '/accounts/minter/asset/status';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AccountsMinterNew = async item => {

  const basx = base + '/accounts/minter/new'
  const head = {
    'Content-Type': 'application/json',
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}