import axios from "axios";
import services from "../data.services/data-services-main.json";
import { decrypt, encrypt } from "./srvc-encr-node";

const base = services.data.find((x) => x.code == "transfers.funds").link;


export const TransfersDataListUser = async (item) => {
  const basx = base + "/transfers/data/list/user";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
