// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'
import FormNeeded from '../webx/form-needed'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { CredentialsCreate } from '../../services/srvc-credentials-realm'
import { CheckAadhaarCodeCheck, CheckAadhaarCodeCreate } from '../../services/srvc-broker-realm'


export default function AssetIDAadhaarCardModule (props) {
  

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const [docs, setDocs] = useState(false);
  const [form, setForm] = useState(false);
  const [code, setCode] = useState(false)
  const [check, setCheck] = useState(false)
  const [memo, setMemo] = useState('Enter Document Number');
  const [info, setInfo] = useState({})

  const [item, setItem] = useState(props.data)
  const [data, setData] = useState({
    number: '',
    code: '',
    trxn: ''
  })


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        // const result = await TokenDetails({
        //   data: { item: id }
        // })
        // console.log('r', result)

        // if (result.stat) setData(result.data)

        setLoader(false)
      }
      fetchData()
    } else {}
  }, [])

  useEffect(() => {
    setDocs(false)
    setForm(false)
    if (data.number !=="" && data.number.length == 12 && !code ) setDocs(true)
    if (data.code !=="" && data.code.length == 6 && !isNaN(data.code)) setForm(true)
  }, [data.number, data.code])

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleCodeCreate = async() => {
    setMemo("Please Wait ..")
    var res
    if (data.number == '999900000123') {
      res = {data: {trxnId: Date.now.toString()}, stat: true, memo: '', trxn: '', srvc:'' }
    } 
    else {
      res = await CheckAadhaarCodeCreate({
        data:{number: data.number}
      })
      // console.log(res)
    }

    if(res.stat) {
      setData({...data, trxn: res.data.trxnId})
      setMemo('OTP Sent to Linked Mobile Number')
      setCode(true)
    }
    else{
      setMemo('Error: Please Check Aadhaar Number')
      setCode(false)
    }
 
  }

  const handleCodeCheck = async() => {
    
    setMemo('Please Wait...')
    var res, checkx = false
    
    if (data.number == '999900000123') {
      if (data.code == '123456') {
        setSubmit(true)
        checkx = true
      }
    }
    else {
      res = await CheckAadhaarCodeCheck({data:{ otp : data.code, transactionId : data.trxn }})
    
      setSubmit(true)
      if (res.stat) {
        setMemo('Code Verification Success')
      checkx = true
      setInfo(res.data)

      } else {
        setMemo('Code Verification Failed')
        checkx = false
      }
    }
    setSubmit(false)
    
    return {data: res?.data || {}, stat: checkx}
  }  

  const handleSubmit = async() => {
    const checkz = await handleCodeCheck()
    
    setMemo('Please Wait...')
    setSubmit(true)
    var result
    var datx = {
      meta: {name: item.name, memo: item.name, item: item.item},
      taxxon: '111fbcc1f9c74d7f87dca9b870dd55914',
      value: {number: data.number, name: asset.name, source: 'self', ...checkz.data},
      feature: {}, 
      status: {mint: true, check: true },
      user: {name: asset.name, mail: asset.mail, item: asset.item}
    }
    if (checkz.stat) {
      // console.log(datx)
      result = await CredentialsCreate({data: datx, srvc: '******'})
      // console.log (result)
      
      if (result.stat) {
        setMemo('Credential Created')
        setDone(true)
        
      } else {
        setMemo('Credential Creation Failed')
        setSubmit(false)
        setDone(false)
      }

    }

  }


  if (loader) return <></>

  return (
    <>
      
      {/* data */}
      <div className='mx-3'>
        <div className="mb-3">  
          <label className="form-label small">Enter Aadhaar Card Number (12 digit) <FormNeeded/></label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.number}
            onChange={({ target }) => {handleChange("number", target.value); }}
            disabled={loader || submit || code}
            placeholder="User Name">
          </input>
        </div>
      </div>

      {/* code */}
      <div className={code? 'mx-3' : 'd-none'}>
        <div className="mb-3">  
          <label className="form-label small">Enter OTP <FormNeeded/></label>
          <input type="text" className="form-control height-md"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.code}
            onChange={({ target }) => {handleChange("code", target.value); }}
            disabled={loader || submit }
            placeholder="123456">
          </input>
        </div>
      </div>

      {/* memo */}
      <div className="mx-3">
        <p className="text-small m-0">{memo}</p>
      </div>

      {/* action */}
      <div className='mx-3'>
        <WebbDividerMedium />
        <div className="d-flex justify-content-between">

          <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type="button"
            disabled={loader || submit}
            onClick={()=> { navigate(-1)}}
          >{loader ? 'Please Wait...' : 'Cancel'}</button>

          <button className={`btn btn-primary border-none rounded-xx text-small ${code ? 'd-none': ''}`}
            type="button"
            disabled={!docs || loader || submit}
            onClick={()=> { handleCodeCreate()}}
          >{loader ? 'Please Wait...' : 'Get OTP'}</button>

          <button className={`btn btn-primary border-none rounded-xx text-small ${code ? '': 'd-none'}`}
            type="button"
            disabled={!form || loader || submit}
            onClick={()=> { handleSubmit()}}
          >{loader ? 'Please Wait...' : 'Continue'}</button>

          
        </div>

        <WebbDividerMedium />
      </div>

    </>
  )
}
