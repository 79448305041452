// transfers
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import listStatus from '../../data.static/data-transfers-status.json'
import { IndividualOffersList } from '../../services/srvc-offers-realm'

export default function OffersListWait (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const [list, setList] = useState(listStatus.data)

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        var datx = {
            user: asset.item
        }
        // console.log(datx)
        var res = await IndividualOffersList({data:datx, client:'', service:''})
        console.log(res)
        if(res.stat){
            setData(res.data.list)
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  // console.log(data)

  // if (loader) return <>
  //   <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>

  //     <span className="align-middle text-lead">
  //       <i className="bx bxs-info-circle text-color-wait"></i>
  //     </span>
  //     <span className='ms-1 text-color-tone'>Please Wait...</span>

  //   </div>
  // </>
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Transfers</span>
        </div>
      </>
    )

  return (
    <>
      <div className='back-color-wite border rounded-xd'>
        {/* header */}
        <div className='border-bottom text-bold p-2 px-3'>
          <div className='d-flex p-2 px-0'>
                       
            <div className='d-md-block' style={{ width: '45%' }}>
              <p className='m-0 text-sm'>
                <span className=''>{'Asset Name'}</span>
              </p>             
            </div>


            <div className=''>
              <p className='m-0 text-sm'>
                <span className=''>{''}</span>
              </p>             
            </div>

            <div className='ms-auto text-end text-sm' style={{ width: '15%' }}>
              <p className='m-0 text-sm'>
                <span className=''>{'Rate'}</span>
              </p>
            </div>
          </div>
        </div>

        {/* data */}
        {data && data.map((item, i) => (
          <div key={i}>
            <div className='d-flex p-2 px-3 cursor hilite' onClick={()=> navigate(`/user/offers/${item.item}/${item?.unit?.item}`)}  >
                <div className='d-none'>
                  <span className=''>
                    <i className={`bx bx-credit-card`}></i>
                  </span>
                </div>        
            
                <div className='d-flex d-none d-md-block' style={{ width: '45%' }}>

                  <div className=''>
                    <p className='m-0 text-sm'>
                      <span className='text-bold'>{item.unit.name}</span>
                    </p>
                    <p className='m-0 text-small text-sm'>
                      <span className=''>{item.meta.memo}</span>
                    </p>  
                  </div>
                
                </div>


                <div className='ms-auto text-end' style={{ width: '15%' }}>
                  <span className={`me-2 `}>
                  {NumberFormat(item.rate[0].rate.nmbr, "w", "2")}
                  </span> 
                  {/* <span className={` ${
                    list.find(x => x.code == item?.actv || 0).color}`}>
                    <i className={`small ${
                      list.find(x => x.code == item?.actv || 0).icon
                    }`}></i>
                  </span>  */}
                </div>

              </div>
              <div className={i < data.length-1 ? 'border-bottom' : ''}></div>
            </div>
          ))}
      </div>

      {/* navs */}
      <WebbDividerSmall />
      <div className={data.length < items ? '' : ''}>
        <div className='d-flex justify-content-between'>
          <div className='' onClick={() => LastIndex()}>
            <NavsButtonBack />
          </div>

          <div className=''>
            <p className='my-3'>{text}</p>
          </div>

          <div className='' onClick={() => NextIndex()}>
            <NavsButtonNext />
          </div>
        </div>
      </div>
    </>
  )
}
