// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'assets')).link

// -----------------

export const AssetUnitCreate = async (item) => {
  
  const basx = base + '/units/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDetails = async (item) => {
  
  const basx = base + '/asse/units/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDataMintSet = async (item) => {
  
  const basx = base + '/units/data/mint/set';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDataBookSet = async (item) => {
  
  const basx = base + '/units/data/book/set';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const AssetUnitDataSaleSet = async (item) => {
  
  const basx = base + '/units/data/sale/set';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetUnitRateSet = async (item) => {
  
  const basx = base + '/units/rate/set';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const AssetUnitListMembers = async (item) => {
  
  const basx = base + '/units/list/members';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":   process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
 const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}