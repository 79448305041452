// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'media.docs')).link

// -----------------

export const DocumentsOnboardStatus = async (item) => {
  
  const basx = base + '/documents/status';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const DocumentSave = async (item) => {
  
  const basx = base + '/documents/save';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}




export const DocumentCreate = async (item) => {
  
  const basx = base + '/documents/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}




export const UserDocumentList = async (item) => {
  
  const basx = base + '/documents/list/user';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserPanCardCheck = async (item) => {
  
  const basx = base + '/document/pan/check';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
export const UserCreateAadhaarOTP = async (item) => {
  
  const basx = base + '/aadhaar/otp/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserCheckAadhaarOTP = async (item) => {
  
  const basx = base + '/aadhaar/otp/check';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data, process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserDocumentDetail = async (item) => {
  
  const basx = base + '/documents/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}