// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { encrypt, decrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'media')).link

// -----------------

export const MediaCreate = async (item) => {
  
  const basx = base + '/media/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}