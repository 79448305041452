// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";

const base = (services.data.find(x => x.code == 'broker.id')).link

// -----------------

export const CheckAadhaarCodeCreate = async (item) => {
  
  const basx = base + '/aadhaar/code/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const CheckAadhaarCodeCheck = async (item) => {
  
  const basx = base + '/aadhaar/code/check';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const CheckPanCard = async (item) => {
  
  const basx = base + '/pancard/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const CheckVoterCard = async (item) => {
  
  const basx = base + '/voterid/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const CheckDriverLicenseCard = async (item) => {
  
  const basx = base + '/driverlicence/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,  process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,  process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}