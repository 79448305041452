// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";


const base = (services.data.find(x => x.code == 'users')).link

// -----------------

export const UserAccountCreate = async (item) => {
  
  const basx = base + '/users/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const UserAccountDetails = async (item) => {
  
  const basx = base + '/users/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
    const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const UserAccountList = async (item) => {
  
  const basx = base + '/users/accounts';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
    const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)
  
  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserOnboardStatus = async (item) => {
  
  const basx = base + '/user/onboard/status';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
    const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const UserDefaultDataRead = async (item) => {
  
  const basx = base + '/users/default/data/read';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  // console.log(datx)

    const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)
  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    //console.log(data)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
      // console.log(error)

    return { code: error.response.status, ...error.response.data }
  }
}

export const UserProfileDetails = async (item) => {
  
  const basx = base + '/users/profile/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
 const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
 const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    // console.log(data)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
export const UserProfileEdit = async (item) => {
  
  const basx = base + '/users/profile/edit';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)
 
  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
export const UserProfileCreate = async (item) => {
  
  const basx = base + '/users/profile/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)
 
  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    // console.log(data)
    return { code: result.status, stat: result.data.stat, data } 
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}