// init
import axios from "axios";
import services from "../data.services/data-services-main.json";
import { decrypt, encrypt } from "./srvc-encr-node";

const base = services.data.find((x) => x.code == "assets").link;

// -----------------

export const AssetsDiscoverList = async (item) => {
  let endpoint = "";
  switch (item.type) {
    case "academics":
      endpoint = "/academics/assets/list/discover";
      break;
    case "events":
      endpoint = "/assets/list/discover";
      break;
    case "social":
      endpoint = "/social/assets/list/discover";
      break;
    case "work":
      endpoint = "/work/assets/list/discover";
      break;
    default:
      endpoint = "/assets/list/discover";
  }
  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetDetails = async (item) => {
   let endpoint = "";
   switch (item.type) {
     case "academics":
       endpoint = "/academics/assets/details";
       break;
     case "events":
       endpoint = "/assets/details";
       break;
     case "social":
       endpoint = "/social/assets/details";
       break;
     case "work":
       endpoint = "/work/assets/details";
       break;
     default:
       endpoint = "/assets/details";
   }
   const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};

export const AssetUnitDetails = async (item) => {
  let endpoint = "";
  switch (item.type) {
    case "academics":
      endpoint = "/academics/assets/units/details";
      break;
    case "events":
      endpoint = "/assets/units/details";
      break;
    case "work":
      endpoint = "/work/assets/units/details";
      break;
    case "social":
      endpoint = "/social/assets/units/details";
      break;
    default:
      endpoint = "/assets/units/details";
  }
  const basx = base + endpoint;
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};
