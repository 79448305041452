import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";

import { useNavigate } from "react-router-dom";
import {
  AssetsUsersOffers,
  CancelOffer,
  EditOffer,
} from "../../services/srvc-offers-realm";

export const MyOffersListUsers = ({ isOfferCreated }) => {
  const { id } = useParams();
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [unitRate, setUnitRate] = useState(null);
  const [unitSaleCount, setUnitSaleCount] = useState(null);
  const [unitWarning, setUnitWarning] = useState("");

  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    const fetchdata = async () => {
      const datx = { user: asset.item, cred: id };
      var res = await AssetsUsersOffers({
        data: datx,
      });
      // console.log("My-offers", res);
      if (res.stat) setData(res.data.list);
      setLoader(false);
      //    var filtered =  res.data.find(docx => docx.mmbr.item == asset.item && docx.mmbr.stat== 1)
      //    console.log(filtered)
    };

    fetchdata();
  }, [refresh, isOfferCreated]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const cancelOffer = () => {
    const fetchdata = async () => {
      var res = await CancelOffer({ data: { item: offerId } });
      // console.log("My-offers-cancel", res);
      if (res.stat) {
        setRefresh(!refresh);
        closeModal();
      }
    };
    fetchdata();
  };

  const editOffer = () => {
    const fetchdata = async () => {
      var res = await EditOffer({
        data: { item: offerId, rate: unitRate },
      });
      // console.log("My-offers-Edit", res);
      if (res.stat) {
        setRefresh(!refresh);
        setIsModalOpen(false);
      }
    };
    fetchdata();
  };

  const SubmitAssetTransfer = async (item) => {
    // setLoader(true)
    // var res = await TransfersAssetSubmit({data:{item: item.item}})
    // console.log(res.data)
    // if(res.stat){ setRefresh(!refresh)}
    // setLoader(false)
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-success"></i>
          </span>
          <span className="ms-1 text-color-tone">No Pending Offers</span>
        </div>
      </>
    );

  return (
    <>
      <div className="back-color-wite border rounded my-4">
        {/* header */}
        <div className="border-bottom text-small text-bold">
          <div className="d-flex p-2 px-3">
            <div className="" style={{ width: "5%" }}>
              <p className="m-0">
                <span className="text-small">
                  <i className="bx bxs-grid-alt text-color-tint"></i>
                </span>
              </p>
            </div>

            <div className="" style={{ minWidth: "45%" }}>
              <p className="m-0">
                <span className="">{"My Offers"}</span>
              </p>
            </div>

            <div className="" style={{ width: "30%" }}>
              <p className="m-0 text-sm">
                <span className="">{"Rate"}</span>
              </p>
            </div>

            <div className="ms-auto text-end text-sm" style={{ width: "20%" }}>
              <p className="m-0 text-sm ">
                <span className="">{"Action"}</span>
              </p>
            </div>
          </div>
        </div>

        {/* data */}
        {data &&
          data.map((item, i) => (
            <div key={i}>
              <div className="d-flex p-2 px-3">
                <div className="" style={{ width: "5%" }}>
                  <p className="m-0">
                    <span className="text">
                      <i className="bx bx-gift text-color-tint"></i>
                    </span>
                  </p>
                </div>

                <div className="" style={{ width: "45%" }}>
                  <p className="m-0">
                    <span className="text-bold">{item.user.name}</span>
                  </p>
                </div>
                <div className="" style={{ width: "30%" }}>
                  <p className="m-0">
                    <span className="text-uppercase">
                      {item.rate.nmbr} {item.rate.tick}
                    </span>
                  </p>
                </div>
                <div className="ms-auto text-end" style={{ width: "20%" }}>
                  <div
                    // onClick={() => navigate(`/user/resale/offer/${item.item}`)}
                    onClick={() => {
                      openModal();
                      setOfferId(item.item);
                      setUnitRate(item.rate.nmbr);
                    }}
                    className={
                      !item?.feat?.claim
                        ? "p-0 text-primary text-decoration-none cursor"
                        : "d-none"
                    }
                  >
                    Edit
                  </div>{" "}
                </div>
              </div>
              <div className={i < data.length - 1 ? "border-bottom" : ""}></div>
            </div>
          ))}
      </div>
      <div className={isModalOpen ? "" : "d-none"}>
        <div className="modal-overlay" id="mint">
          <div className="modal-content rounded-xd p-3">
            <div className="modal-header">
              <h6 className="modal-title ">Edit Offer</h6>
              <button
                type="button"
                className="btn-close cursor z-2"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body mt-3">
              <div className="">
                <label className="text-small mb-1 mt-3">Unit Rate</label>
                <input
                  value={unitRate}
                  onChange={(e) => setUnitRate(e.target.value)}
                  className="form-control"
                  pattern="[1-9][0-9]*"
                />
              </div>
            </div>
            <div className="d-flex mt-5">
              <div className="me-auto">
                <button
                  type="button"
                  className="btn btn-light text-small rounded-xx bg-body-tertiary"
                  onClick={() => {
                    if (
                      window.confirm("Do you really want to delete this offer?")
                    ) {
                      cancelOffer();
                    }
                  }}
                >
                  Cancel Offer
                </button>
              </div>
              <div className="text-end">
                <button
                  disabled={unitRate == "" || unitRate < 1}
                  onClick={editOffer}
                  type="button"
                  className="btn btn-outline-primary text-small rounded-xx"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
