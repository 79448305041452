// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import {
  UserProfileCreate,
  UserProfileDetails,
  UserProfileEdit,
} from "../../services/srvc-users-realm";
import FormNeeded from "../webx/form-needed";

export default function UserShareDefaultModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const [inputs, setInputs] = useState({
    name: "",
    mail: "",
    whatsapp: "",
    contact: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
    whatsapplink: "",
    facebooklink: "",
    linkedinlink: "",
  });

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await UserProfileDetails({
          data: {
            user: asset.item,
          },
        });

        if (result.stat) {
          setData(result.data);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);

  useEffect(() => {
    setInputs({
      name: data ?.name,
      mail: data ?.mail,
      whatsapp: data ?.contact?.whatsapp,
      contact: data?.contact?.mobile,
      city: data ?.address?.city,
      district: data ?.address?.district,
      state: data?.address?.state,
      country: data?.address?.country,
      pincode: data?.address?.pincode,
      instagramlink: data?.sites?.instagram,
      facebooklink: data ?.sites?.facebook,
      linkedinlink: data ?.sites?.linkedin,
    });
  }, [data ]);

  const handleInputChange = (name, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleAddressSubmit = async () => {
    const address = {
      city: inputs.city,
      district: inputs.district,
      state: inputs.state,
      country: inputs.country,
      pin: inputs.pincode,
    };

    const result = await UserProfileEdit({
      data: {
        user: asset.item,
        field: "address",
        address,
      },
    });
    setRefresh(!refresh);
  };

  const handleContactSubmit = async () => {
    const info = {
      mail: inputs.mail,
      mobile: inputs.contact,
      whatsapp: inputs.whatsapp,
      name: inputs.name,
    };

    const result = await UserProfileEdit({
      data: {
        user: asset.item,
        field: "info",
        info,
      },
    });
    setRefresh(!refresh);
  };
  const handleSitesSubmit = async () => {
    const sites = {
      linkedin: inputs.linkedinlink,
      instagram: inputs.instagramlink,
      facebook: inputs.facebooklink,
    };
    const result = await UserProfileEdit({
      data: {
        user: asset.item,
        field: "sites",
        sites,
      },
    });
    setRefresh(!refresh);
  };

  const handleAddProfile = async () => {
    const user = {
      mail: asset.mail,
      mobile: asset.mobile,
      item: asset.item,
      name: asset.name,
    };

    const result = await UserProfileCreate({
      data: {
        user,
      },
    });
    setRefresh(!refresh);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );
  // console.log(data);
  return (
    <>
      {data.length != 0 && (
        <div>
          <div className="back-color-wite rounded-xd p-3 border">
            <p
              className=" fw-bold text-color-tone m-0"
              style={{ fontSize: "20px" }}
            >
              Contact
            </p>
            <div className="me-auto">
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-user-circle bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                >
                  {data?.name || "******"}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-envelope bx-sm"></i>
                </p>
                <p
                  className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                >
                  {data?.contact?.mail}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bxl-whatsapp bx-sm"></i>
                </p>
                <p className="text-color-next text-medium text-normal m-0">
                  {data?.contact?.whatsapp}
                </p>
              </div>
              <div className="me-auto d-flex gap-4 my-2">
                <p className=" text-color-tone m-0">
                  <i class="bx bx-phone-call bx-sm"></i>
                </p>
                <p className="text-color-next text-medium text-normal m-0">
                  {data?.contact?.mobile}
                </p>
                <div className="ms-auto">
                  <span
                    className="p-1 px-2 back-color-lite rounded text-mini cursor"
                    data-bs-toggle="modal"
                    data-bs-target={`#modEditContact`}
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="back-color-wite rounded-xd p-3 border mt-4">
              <div className="d-flex flex-column ">
                <div></div>
                <div className="me-auto w-100">
                  <p
                    className=" fw-bold text-color-tone m-0"
                    style={{ fontSize: "20px" }}
                  >
                    Address
                  </p>

                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                    <i class='bx bx-home-circle bx-sm' ></i>        
                                </p>
                    <p
                      className="text-color-next text-medium w-75 text-normal m-0 text-break
            "
                    >
                      {data?.address?.city},
                    {data?.address?.district},
                    {data?.address?.state} ,
                    {data?.address?.country}
                    </p>
                  </div>
                 
                </div>
                <div className="ms-auto">
                  <span
                    className="p-1 px-2 back-color-lite rounded text-mini cursor"
                    data-bs-toggle="modal"
                    data-bs-target={`#modEditAddress`}
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>

            <div className="back-color-wite rounded-xd p-3 border mt-4">
              <div className="d-flex flex-column ">
                <div></div>

                <div className="me-auto ">
                  <p
                    className=" fw-bold text-color-tone m-0"
                    style={{ fontSize: "20px" }}
                  >
                    Social Media
                  </p>
                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                      <i class="bx bxl-instagram bx-sm"></i>
                    </p>
                    <p
                      className="text-color-next text-medium  text-normal m-0 text-break
            "
                    >
                        {data?.sites?.instagram}
                     
                    </p>
                  </div>
                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                      <i class="bx bxl-facebook bx-sm"></i>
                    </p>
                    <p
                      className="text-color-next text-medium  text-normal m-0 text-break
            "
                    >
                        {data?.sites?.facebook}
                      
                    </p>
                  </div>
                  <div className="me-auto d-flex gap-4 my-2">
                    <p className=" text-color-tone m-0">
                      <i class="bx bxl-linkedin bx-sm"></i>
                    </p>
                    <p
                      className="text-color-next text-medium  text-normal m-0 text-break
            "
                    >
                        {data?.sites?.linkedin}
                     
                    </p>
                  </div>
                </div>

                <div className="ms-auto">
                  <span
                    className="p-1 px-2 back-color-lite rounded text-mini cursor"
                    data-bs-toggle="modal"
                    data-bs-target={`#modEditSocial`}
                  >
                    Edit
                  </span>
                </div>

                <div
                  className="modal fade"
                  id="modEditContact"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog rounded-xd">
                    <div className="modal-content p-0 m-0 rounded-xd w-100 w-100">
                      <div className="modal-header border-none">
                        <p className="text-normal align-middle m-0 p-0">Edit</p>
                        <button
                          type="button"
                          className="btn-close text-small"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      {/* modal content */}
                      <div className="modal-body border-none">
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Name <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.name}
                            onChange={({ target }) => {
                              handleInputChange("name", target.value);
                            }}
                          ></input>
                        </div>{" "}
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Email <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.mail}
                            onChange={({ target }) => {
                              handleInputChange("mail", target.value);
                            }}
                          ></input>
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Whatsapp <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.whatsapp}
                            onChange={({ target }) => {
                              handleInputChange("whatsapp", target.value);
                            }}
                          ></input>
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Contact <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.contact}
                            onChange={({ target }) => {
                              handleInputChange("contact", target.value);
                            }}
                          ></input>
                        </div>
                      </div>

                      <div className="d-flex modal-footer border-none">
                        <div className="">
                          <button
                            className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="ms-auto">
                          <button
                            className="btn btn-primary btn-sm text-small rounded-xx"
                            onClick={() => handleContactSubmit()}
                            data-bs-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="modEditAddress"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog rounded-xd">
                    <div className="modal-content p-0 m-0 rounded-xd w-100 w-100">
                      <div className="modal-header border-none">
                        <p className="text-normal align-middle m-0 p-0">Edit</p>
                        <button
                          type="button"
                          className="btn-close text-small"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      {/* modal content */}
                      <div className="modal-body border-none">
                        <div className="mb-3">
                          <label className="form-label text-small">
                            City <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.city}
                            onChange={({ target }) => {
                              handleInputChange("city", target.value);
                            }}
                          ></input>
                        </div>{" "}
                        <div className="mb-3">
                          <label className="form-label text-small">
                            District <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.district}
                            onChange={({ target }) => {
                              handleInputChange("district", target.value);
                            }}
                          ></input>
                        </div>{" "}
                        <div className="mb-3">
                          <label className="form-label text-small">
                            State <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.state}
                            onChange={({ target }) => {
                              handleInputChange("state", target.value);
                            }}
                          ></input>
                        </div>{" "}
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Country <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.country}
                            onChange={({ target }) => {
                              handleInputChange("country", target.value);
                            }}
                          ></input>
                        </div>{" "}
                        <div className="mb-3 d-none">
                          <label className="form-label text-small">
                            Pincode <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.pincode}
                            onChange={({ target }) => {
                              handleInputChange("pincode", target.value);
                            }}
                          ></input>
                        </div>
                      </div>

                      <div className="d-flex modal-footer border-none">
                        <div className="">
                          <button
                            className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="ms-auto">
                          <button
                            className="btn btn-primary btn-sm text-small rounded-xx"
                            onClick={() => handleAddressSubmit()}
                            data-bs-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="modEditSocial"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog rounded-xd">
                    <div className="modal-content p-0 m-0 rounded-xd w-100">
                      <div className="modal-header border-none">
                        <p className="text-normal align-middle m-0 p-0">Edit</p>
                        <button
                          type="button"
                          className="btn-close text-small"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      {/* modal content */}
                      <div className="modal-body border-none">
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Instagram <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.instagramlink}
                            onChange={({ target }) => {
                              handleInputChange("instagramlink", target.value);
                            }}
                          ></input>
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Facebook <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.facebooklink}
                            onChange={({ target }) => {
                              handleInputChange("facebooklink", target.value);
                            }}
                          ></input>
                        </div>
                        <div className="mb-3">
                          <label className="form-label text-small">
                            Linkedin <FormNeeded />
                          </label>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={inputs.linkedinlink}
                            onChange={({ target }) => {
                              handleInputChange("linkedinlink", target.value);
                            }}
                          ></input>
                        </div>
                      </div>

                      <div className="d-flex modal-footer border-none">
                        <div className="">
                          <button
                            className="btn btn-outline-secondary btn-sm text-small rounded-xx"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="ms-auto">
                          <button
                            className="btn btn-primary btn-sm text-small rounded-xx"
                            onClick={() => handleSitesSubmit()}
                            data-bs-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`${data.length == 0 ? "" : "d-none"}`}>
        <div className="mt-3 " style={{ width: "15%" }}>
          <button
            className="btn btn-primary btn-sm text-small rounded-xx w-100"
            onClick={handleAddProfile}
          >
            Add Profile
          </button>
        </div>
      </div>
    </>
  );
}
