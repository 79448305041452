// encyrpt x decrypt

const CryptoJS = require('crypto-js');


export const TextSecure = async (item) => {
  
  const {text, secret} = item

  try {
    var newtext = CryptoJS.AES.encrypt(text, secret).toString();
    // console.log(newtext)
  
    return {data: newtext}
  } catch (error) {
    // console.log (error)
    return {data: false}
  }

}


export const TextReverse = async (item) => {
  
  const {text, secret} = item

  try {
    var bytes  = CryptoJS.AES.decrypt(text, secret);
    var reversetext = bytes.toString(CryptoJS.enc.Utf8);
    // console.log(reversetext)

    return {data: reversetext}

  } catch (error) {
    // console.log (error)
    return {data: false}
  }

}

export  const encrypt = async (data, secret) => {
  try {
   let string = JSON.stringify(data) 
   let encrypted = CryptoJS.AES.encrypt(string, secret).toString();
   return encrypted
  } catch (error) {
    console.error('Encryption error:', error);
  }
};

export const decrypt = async (encryptedString, secret) => {
  try {
      let bytes = CryptoJS.AES.decrypt(encryptedString, secret)
      let data = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(data);
  } catch (error) {
    console.error('Decryption error:', error);
  }
};