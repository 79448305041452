// init
import axios from "axios";
import services from '../data.services/data-services-main.json'
import { decrypt, encrypt } from "./srvc-encr-node";


const base = (services.data.find(x => x.code == 'tokens.rand')).link

// -----------------

export const TokenClaim = async (item) => {
  
  const basx = base + '/tokens/claim';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    // console.log(data)
    return { code: result.status, stat: result.data.stat, data }
    } catch (error) {
      // console.log(error)
    return { code: error.response.status, ...error.response.data }
  }
}

export const TokenTransfer = async (item) => {
  
  const basx = base + '/tokens/transfer';
  const head = { 
    "Content-Type": "application/json",
    "Authorization":  process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc:  process.env.REACT_APP_WEBB_SITE_SRVC }
  const payload = await encrypt(datx,process.env.REACT_APP_WEBB_SITE_SCRT)

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,process.env.REACT_APP_WEBB_SITE_SCRT)
    return { code: result.status, stat: result.data.stat, data }
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}